import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { STATIC_HOST } from "../constant/config";
import FileDropzone from "../components/FileDropzone";
import HTML_Logo from "../utils/images/html.png";
import JS_Logo from "../utils/images/javascript.png";
import Css_Logo from "../utils/images/css.png";
import Json_Logo from "../utils/images/json.png";
import Info from "../utils/images/infor_logo.png";
import Python from "../utils/images/Python-logo.png";
import MP3 from "../utils/images/mp3.png";
import XML from "../utils/images/Xml_logo.png";
import { FiMoreHorizontal } from "react-icons/fi";
import InputFileNameModal from "../components/InputFileNameModal";
import ConfirmModal from "../components/ConfirmModal";
import { toast, ToastContainer } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { IoMdArrowDropdown } from "react-icons/io";
import WholePageDropZone from "../components/WholePageDropZone";

const DropDownItem = ({ label, onClick }) => {
  return (
    <a
      className="flex items-center py-2  text-sm text-gray-800 
            hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 
            dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="px-2">{label}</div>
    </a>
  );
};

const PREVIEW_FILE_TYPE = [
  "jpg",
  "png",
  "jpeg",
  "gif",
  "mp4",
  "mp3",
  "bmp",
  "webp",
];

const EDIT_PREVIEW_FILE_TYPE = ["html", "md"];
const IGNORE_FILE_TYPE = ["zip", "rar", "exe", "phong"];

const ProjectSideBar = ({
  projectCode,
  projectName,
  files,
  setPreviewFile,
  setEditFile,
  getFileTypeFromName,
  setTotalFiles,
  uid,
  setLoading,
  setActiveFile,
  activeFile,
  contentChanged,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [createNewFile, setCreateNewFile] = useState(false);
  const [newFileName, setNewFileName] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [mode, setMode] = useState("");
  const [duplicateFile, setDuplicateFile] = useState(null);
  const [oldFilename, setOldFilename] = useState("");
  const [fileID, setFileID] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const getLogo = (file) => {
    let logo = null;
    if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "js" ||
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "javascript"
    ) {
      logo = <img src={JS_Logo} alt="JS Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "mp3"
    ) {
      logo = <img src={MP3} alt="mp3 Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "css"
    ) {
      logo = <img src={Css_Logo} alt="Css Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "html"
    ) {
      logo = <img src={HTML_Logo} alt="Html Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "json"
    ) {
      logo = <img src={Json_Logo} alt="Json Logo" className="w-[17px]" />;
    } else if (
      file.originalname
        .toLowerCase()
        ?.substring(file.originalname.lastIndexOf(".") + 1) === "png" ||
      file.originalname
        .toLowerCase()
        ?.substring(file.originalname.lastIndexOf(".") + 1) === "jpg"
    ) {
      logo = <img src={file.url} alt="Upload Image" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "py"
    ) {
      logo = <img src={Python} alt="Python file" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "txt" ||
      file.originalname?.substring(
        file.originalname.lastIndexOf(".") + 1 === "md"
      )
    ) {
      logo = <img src={Info} alt="text file" className="w-[17px]" />;
    }
    return logo;
  };

  const handleRenameFile = (x) => {
    setMode("rename");
    document.getElementById("open-modal-rename")?.click();
    setOldFilename(x.originalname);
    document.getElementById("input-filename").value = x.originalname;
    setFileID(x.filename);
  };

  useEffect(() => {
    let queryFileName = searchParams.get("fileName");
    if (queryFileName && files) {
      let matchFile = files.find((file) => file.filename == queryFileName);
      if (matchFile) {
        handleClickFile(null, matchFile);
      }
    }
  }, [files]);

  const handleClickFile = async (e, file) => {
    if (!contentChanged) {
      setCreateNewFile(false);
      setSelectedFile(file.filename);

      setNewFileName("");
      window.history.pushState(
        {},
        "",
        window.location.origin +
          "/project/" +
          projectCode +
          "?fileName=" +
          file.filename
      );

      if (IGNORE_FILE_TYPE.includes(file.type?.toLowerCase())) {
        return;
      }
      if (PREVIEW_FILE_TYPE.includes(file.type?.toLowerCase())) {
        if (setPreviewFile) setPreviewFile(file);
        if (setEditFile) setEditFile(null);
        return;
      }
      if (EDIT_PREVIEW_FILE_TYPE.includes(file.type?.toLowerCase())) {
        if (setPreviewFile) setPreviewFile(file);
        if (setEditFile) setEditFile(file);
        return;
      }

      if (setEditFile) setEditFile(file);
    } else {
      if (window.confirm("Changes you made may not be saved.")) {
        setCreateNewFile(false);
        setSelectedFile(file.filename);

        setNewFileName("");
        window.history.pushState(
          {},
          "",
          window.location.origin +
            "/project/" +
            projectCode +
            "?fileName=" +
            file.filename
        );

        if (IGNORE_FILE_TYPE.includes(file.type?.toLowerCase())) {
          return;
        }
        if (PREVIEW_FILE_TYPE.includes(file.type?.toLowerCase())) {
          if (setPreviewFile) setPreviewFile(file);
          if (setEditFile) setEditFile(null);
          return;
        }
        if (EDIT_PREVIEW_FILE_TYPE.includes(file.type?.toLowerCase())) {
          if (setPreviewFile) setPreviewFile(file);
          if (setEditFile) setEditFile(file);
          return;
        }

        if (setEditFile) setEditFile(file);
      }
    }
  };

  const handleAddNewFile = async () => {
    document.getElementById("open-modal-rename")?.click();
    setMode("newfile");
    if (document.getElementById("input-filename").value != "") {
      document.getElementById("input-filename").value = "";
    }
  };

  const handleCopy = (x) => {
    if (!navigator || !navigator.clipboard) return;
    navigator.clipboard.writeText(x.url);
    toast.success("URL Copied!");
  };

  const handleDeleteFile = async (x) => {
    try {
      const res = await axios.delete(`${STATIC_HOST}file/${x.filename}`, {
        data: {
          uid: uid,
          project: projectCode,
        },
      });
      if (res.status === 200) {
        setTotalFiles((prevTotalFiles) => {
          const updatedFiles = prevTotalFiles.filter(
            (filename) => filename.filename !== x.filename
          );
          return [...updatedFiles];
        });
        toast.success(`File deleted successfully`);

        if (activeFile.filename === x.filename) {
          setActiveFile(null);
          window.location.href =
            window.location.origin + "/project/" + projectCode;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getContentFile = async (x) => {
    const res = await axios.get(
      `${STATIC_HOST}project/${projectCode}/${x.filename}`
    );
    return res.data;
  };

  const handleDownloadZip = async (e) => {
    var zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      try {
        const content = await getContentFile(files[i]);
        zip.file(files[i].filename, content);
      } catch (error) {
        console.error(`Error fetching file ${files[i].filename}:`, error);
      }
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${projectName}.zip`);
    });
  };

  return (
    <div className="w-full h-full flex flex-col w-64 bg-slate-50 border-r border-gray-200">
      <div className="px-2 py-1 flex w-full border-b border-slate-300 py-2 bg-slate-200">
        <div className="font-mono flex w-full font-bold leading-none items-center">
          <div className="grow max-h-[34px] overflow-hidden max-w-[200px] truncate">
            {projectName || "No name"}
          </div>

          {/* projectDropdown */}
          <div className="hs-dropdown relative inline-flex select-none">
            <button
              id="hs-dropdown-default"
              type="button"
              className="hs-dropdown-toggle grid place-items-center w-8 px-1 py-1 hover:bg-gray-100 rounded-md"
            >
              <IoMdArrowDropdown size="1rem" />
            </button>

            <div
              className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] 
                        hs-dropdown-open:opacity-100 opacity-0 w-72 hidden z-10 mt-2 min-w-[15rem] 
                        bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border 
                        dark:border-gray-700 dark:divide-gray-700"
              aria-labelledby="hs-dropdown-default"
            >
              <DropDownItem
                label="Rename"
                onClick={(e) => {
                  console.log("Rename clicked");
                }}
              />
              <DropDownItem
                label="Download"
                onClick={(e) => {
                  handleDownloadZip(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full grow relative ${
          files.length >= 13 ? "overflow-y-scroll" : "overflow-y"
        } `}
      >
        <div className="w-full h-full absolute">
          <WholePageDropZone
            treeData={treeData}
            setTreeData={setTreeData}
            projectCode={projectCode}
            // files={files}
            setTotalFiles={setTotalFiles}
            uid={uid}
            setLoading={setLoading}
          />
        </div>
        {(!files || !files.length) && (
          <div className="text-gray-400 py-5 px-2 text-center bg-slate-100 mt-2">
            <i>There is no files</i>
          </div>
        )}
        {files &&
          Array.isArray(files) &&
          files.map((file, index) => (
            <div key={index}>
              <ul
                className={`select-none flex flex-col font-medium px-2 hover:bg-slate-200 cursor-pointer 
                  ${selectedFile === file.filename && "bg-slate-200"}
                  `}
              >
                <div
                  className="w-full flex justify-between lg:max-h-[36px] overflow-y-hidden"
                  onClick={(e) => handleClickFile(e, file)}
                >
                  <div
                    className={`relative inline-flex items-center gap-x-2 py-2 px-1 text-sm 
                    text-gray-800 dark:text-white flex`}
                    id="file"
                  >
                    {getLogo(file)}
                    <div className="max-w-[130px] truncate">
                      {file?.filename}
                    </div>
                  </div>
                  <div className="hs-dropdown relative inline-flex">
                    <button
                      id="hs-dropdown-with-dividers"
                      type="button"
                      className="hs-dropdown-toggle"
                    >
                      <FiMoreHorizontal />
                    </button>
                    <div
                      className="absolute z-30 hs-dropdown-menu hidden transition-[opacity,margin] duration hs-dropdown-open:opacity-100
                              opacity-0 min-w-[7rem] bg-white shadow-md rounded-lg p-2 mt-2 divide-y divide-gray-200 dark:bg-gray-800 
                              dark:border dark:border-gray-700 dark:divide-gray-700"
                      aria-labelledby="hs-dropdown-with-dividers"
                    >
                      <DropDownItem
                        label="Copy URL"
                        onClick={(e) => {
                          handleCopy(file);
                        }}
                      />

                      <DropDownItem
                        label="Open Preview In New Tab"
                        onClick={(e) => {
                          window.open(
                            `${STATIC_HOST}project/${projectCode}/${file.originalname}`,
                            "_blank"
                          );
                        }}
                      />
                      <DropDownItem
                        label="Rename"
                        onClick={(e) => {
                          handleRenameFile(file);
                        }}
                      />
                      <DropDownItem
                        label="Delete"
                        onClick={(e) => {
                          handleDeleteFile(file);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          ))}
      </div>

      <div className="px-4 w-full border-t border-slate-300 pt-2 border-gray-200">
        <div className="w-full">
          <FileDropzone
            treeData={treeData}
            setTreeData={setTreeData}
            projectCode={projectCode}
            files={files}
            setTotalFiles={setTotalFiles}
            uid={uid}
            setLoading={setLoading}
          />
        </div>
        <button
          type="button"
          onClick={handleAddNewFile}
          disabled={newFileName != ""}
          className={`py-3 w-full text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white ${
            newFileName != ""
              ? "bg-blue-300"
              : "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
          }`}
        >
          Add new file
        </button>
      </div>

      {/* input filename Modal */}
      <InputFileNameModal
        mode={mode}
        projectCode={projectCode}
        onActionSuccess={(oldName, newName) => {
          window.location.href =
            window.location.origin +
            "/project/" +
            projectCode +
            "?fileName=" +
            newName;
        }}
        oldFilename={oldFilename}
        existFileNames={files.map((file) => file.filename)}
        uid={uid}
      />

      {/* Confirm Modal */}
      <ConfirmModal
        rename={mode}
        duplicateFile={duplicateFile}
        projectCode={projectCode}
        getFileTypeFromName={getFileTypeFromName}
        setTotalFiles={setTotalFiles}
        files={files}
        uid={uid}
      />
    </div>
  );
};

export default ProjectSideBar;
