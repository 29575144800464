import "./App.css";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import Project from "./pages/Project";
import Project_cua_Hien from "./pages/Project_cua_Hien";
import MyProjects from "./pages/MyProjects";
import { HiHome } from "react-icons/hi";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  function handleClick() {
    location.pathname === "/" ? window.location.reload(false) : navigate("/");
  }

  return (
    <div className="h-screen w-screen">
      <div className="flex justify-center bg-slate-100 py-2">
        <div className="flex w-full max-w-[1280px]">
          <HiHome
            size="2.5rem"
            onClick={handleClick}
            className="cursor-pointer"
            color="#1C6269"
          />
          <h1
            className="text-3xl h-10 text-center font-bold m-auto cursor-pointer select-none
                        bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-clip-text text-transparent"
            onClick={handleClick}
          >
            digital.auto studio
          </h1>

          <a
            onClick={() => {
              window.open(
                `https://bestudio.digitalauto.tech/project/QDC3xxv1HJlg/index.html`,
                "_blank"
              );
            }}
            className="flex items-center text-green-600 text-lg underline cursor-pointer"
          >
            How to use
          </a>
        </div>
      </div>
      <div className="h-[calc(100vh-60px)] w-full">
        <Routes>
          <Route path="/" element={<MyProjects />} />
          <Route path="project/:id" element={<Project />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
