import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { STATIC_HOST } from "../constant/config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import { getFileTypeFromName } from "../utils/filename";

InputFileNameModal.propTypes = {};

function InputFileNameModal({
  mode,
  onActionSuccess,
  projectCode,
  oldFilename,
  uid,
  existFileNames,
}) {
  const [language, setLanguage] = useState("");
  const [finalFilename, setFinalFilename] = useState(oldFilename);
  const [newFileName, setNewFileName] = useState(oldFilename);
  const [isExisting, setIsExisting] = useState(false);

  useEffect(() => {
    if (!newFileName) {
      setFinalFilename("");
      return;
    }
    let arFilename = newFileName.split(".");
    if (arFilename.length == 1) {
      let tmpLanguage = "js";
      if (language) {
        tmpLanguage = language;
      } else {
        setLanguage("js");
      }
      setFinalFilename(newFileName + "." + tmpLanguage);
    } else {
      let typingExt = arFilename[arFilename.length - 1].toLowerCase();
      if (language != typingExt) {
        setLanguage(typingExt);
      }
      setFinalFilename(newFileName);
    }
  }, [newFileName, language]);

  const handleInputFile = (e) => {
    setNewFileName(e.target.value);
  };

  const handleEditFilename = async () => {
    if (mode == "rename") {
      try {
        await axios.put(`${STATIC_HOST}project/${projectCode}/${oldFilename}`, {
          filename: finalFilename,
        });
        if (onActionSuccess) {
          onActionSuccess(oldFilename, finalFilename);
        }
      } catch (error) {
        console.error("Error occurred while updating the file:", error);
      }
    } else {
      try {
        let convertedName = convertFileName(newFileName.trim());
        const res = await axios.post(`${STATIC_HOST}project/${projectCode}`, {
          filename: convertedName,
          content: "",
          uid: uid,
          type: getFileTypeFromName(convertedName),
        });
        toast.success("File created successfully");
        setNewFileName("");
        onActionSuccess("", finalFilename);
      } catch (error) {
        // Handle error
        console.error("Error creating file:", error);
        toast.error("Failed to create file");
      } finally {
        // setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    if (!existFileNames) setIsExisting(false);
    setIsExisting(existFileNames.includes(finalFilename));
  }, [finalFilename]);

  const convertFileName = (fileName) => {
    if (fileName.includes(".")) {
      return fileName;
    } else {
      return fileName + "." + language;
    }
  };

  return (
    <div>
      <button
        type="button"
        className="hidden"
        data-hs-overlay="#hs-focus-management-modal-rename"
        id="open-modal-rename"
      >
        Open modal
      </button>
      <div
        id="hs-focus-management-modal-rename"
        className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                {mode == "rename" ? "Change file name" : "Add new file"}
              </h3>
              <button
                id="closeicon-rename-modal"
                type="button"
                className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                data-hs-overlay="#hs-focus-management-modal-rename"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-3.5 h-3.5"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div className="px-4 p-4 overflow-y-auto flex justify-center items-center gap-x-2">
              <div className="w-[350px]">
                <input
                  type="text"
                  required
                  id="input-filename"
                  className="border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 
                            focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                  placeholder="Enter your file name"
                  autoFocus
                  onInput={handleInputFile}
                />
              </div>
              <div className="">
                <select
                  className="border rounded-md placeholder:italic placeholder:text-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 py-2 px-2 h-[46px]"
                  onChange={(e) => {
                    setLanguage(e.target.value || "text");
                  }}
                  value={language}
                >
                  <option value="js">Javascript</option>
                  <option value="html">HTML</option>
                  <option value="css">CSS</option>
                  <option value="py">Python</option>
                  <option value="text">Plain Text</option>
                  <option value="json">JSON</option>
                  <option value="xml">XML</option>
                  <option value="text">CSV</option>
                </select>
              </div>
            </div>
            {isExisting && (
              <span className="px-9 pb-3 overflow-y-auto text-red-500 text-sm">
                File is already existed
              </span>
            )}

            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
              <button
                type="button"
                id="closebtn-rename-modal"
                className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                data-hs-overlay="#hs-focus-management-modal-rename"
              >
                Close
              </button>
              <button
                data-hs-overlay="#hs-focus-management-modal-rename"
                className={`py-3 px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white
               ${
                 isExisting || !newFileName
                   ? "bg-blue-300"
                   : "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
               }`}
                onClick={handleEditFilename}
                disabled={isExisting || !newFileName}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputFileNameModal;
