import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";

LoadingDialog.propTypes = {};

function LoadingDialog(props) {
  return (
    <Dialog>
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
        <div className="w-10 h-10 flex items-center justify-center">
          <div
            style={{ width: `35px`, height: `35px` }}
            className="animate-spin"
          >
            <div className="h-full w-full border-4 border-t-blue-500 border-b-blue-700 rounded-[50%]"></div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default LoadingDialog;
