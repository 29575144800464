import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  MdOutlineKeyboardArrowDown,
  MdVerticalAlignBottom,
} from "react-icons/md";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { STATIC_HOST } from "../constant/config";
import { json, useNavigate, useParams } from "react-router-dom";
import Dropzone from "../components/Dropzone";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import FileDropzone from "../components/FileDropzone";
import HTML_Logo from "../utils/images/html.png";
import JS_Logo from "../utils/images/javascript.png";
import Css_Logo from "../utils/images/css.png";
import Json_Logo from "../utils/images/json.png";
import Info from "../utils/images/infor_logo.png";
import Python from "../utils/images/Python-logo.png";
import XML from "../utils/images/Xml_logo.png";
import { FiMoreHorizontal } from "react-icons/fi";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";

Project.propTypes = {};

function Project(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [projectID, setProjectID] = useState("");
  const [fileID, setFileID] = useState([]);
  const [tabName, setTabName] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [originalContent, setOriginalContent] = useState("");
  const [currentFileID, setCurrentFileID] = useState("");
  const [newFileName, setNewFileName] = useState("");
  const [createNewFile, setCreateNewFile] = useState(false);
  const [fileURL, setFileURL] = useState("");

  const [language, setLanguage] = useState("javascript");
  const [fileType, setFileType] = useState("javascript");
  const [content, setContent] = useState("");

  const [treeData, setTreeData] = useState([]);

  const location = useLocation();
  const fileData = location.state?.file; //filename of project
  const newProjectName = location.state?.newProjectName;

  const [totalFiles, setTotalFiles] = useState([]); //url, filename, originalname

  const [duplicateFile, setDuplicateFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [checkContent, setCheckContent] = useState(false);

  const [compareContent, setCompareContent] = useState(0);

  let { id } = useParams();

  const [uid, setUid] = useState(localStorage.getItem("uid"));

  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
  }, []);

  useEffect(() => {
    if (id == "0" || id == "new-file") {
      document.getElementById("open-modal-button").click();
    } else {
      setProjectID(id);
    }
  }, [id]);

  const fetchFileData = async () => {
    const res = await axios.get(`${STATIC_HOST}project/${id}`);

    const filePromises = res.data.files?.map(async (x) => {
      const res = await axios.get(`${STATIC_HOST}file/${x}`);
      return {
        originalname: res.data.originalname,
        url: res.data.url,
        filename: res.data.filename,
        type: res.data.type,
      };
    });
    setLoading(false);
    if (filePromises) {
      const resolvedFiles = await Promise.all(filePromises);
      setTotalFiles(resolvedFiles);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchFileData();
  }, [id]);

  const handleAddNewFile = async () => {
    document.getElementById("open-modal-rename")?.click();
    setTabName("");
    setRename(false);
    if (document.getElementById("input-filename").value != "") {
      document.getElementById("input-filename").value = "";
    }
    if (content != "") {
      setContent("");
    }
  };

  const handleContentChange = (e) => {
    setContent(e);
  };

  // if (content != "") {
  //   setCompareContent(content?.localeCompare(originalContent));
  // }

  useEffect(() => {
    setCompareContent(content?.localeCompare(originalContent));
  }, [content]);

  const handleFileEdit = async (e) => {
    setIsSaving(true);
    if (currentFileID) {
      // Edit file
      if (compareContent !== 0) {
        try {
          await axios.put(
            `${STATIC_HOST}project/${projectID}/${currentFileID}`,
            {
              content: content,
            }
          );
          toast.success("Edit successful");
          setCheckContent(!checkContent);
          setCompareContent(0);
        } catch (error) {
          // Handle error
          console.error("Error editing file:", error);
          toast.error("Failed to edit file");
        } finally {
          setIsSaving(false);
          setLoading(false);
        }
      }
    } else {
      // Create new file
      if (newFileName === "") {
        toast.error("Please enter a file name");
        setIsSaving(false);
      } else {
        try {
          const res = await axios.post(`${STATIC_HOST}project/${projectID}`, {
            projectID: projectID,
            filename: newFileName.trim(),
            content: content,
            uid: uid,
            type: fileType,
          });
          toast.success("File created successfully");
          setNewFileName("");
          setTabName(res.data.originalname);
          setTotalFiles(totalFiles.concat(res.data));
          setFileID((fileID) => [...fileID, res.filename]);
          setFileURL(res.data.url);
          setCreateNewFile(false);
          setContent(content);
          setCurrentFileID(res.data.filename);
          setCompareContent(0);
          setCheckContent(!checkContent);

          setLanguage(
            res.data.originalname.substring(
              res.data.originalname.lastIndexOf(".") + 1
            )
          );
          setFileType(
            res.data.originalname.substring(
              res.data.originalname.lastIndexOf(".") + 1
            )
          );
        } catch (error) {
          // Handle error
          console.error("Error creating file:", error);
          toast.error("Failed to create file");
        } finally {
          setIsSaving(false);
        }
      }
    }
  };
  const [openImg, setOpenImg] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [vidFile, setVidFile] = useState("");

  const [tempHTML, setTempHTML] = useState("");

  useEffect(() => {
    totalFiles.forEach((x) => {
      axios
        .get(`${STATIC_HOST}file/${x.filename}`, {
          params: { project: projectID },
        })
        .then((res) => {
          const fileExtension = x.originalname.split(".").pop().toLowerCase();

          if (fileExtension === "html") {
            setTempHTML(null);
            setTimeout(() => {
              setTempHTML(res.data.url);
            }, 500);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    });
  }, [checkContent]);

  const handleClickFile = async (e, file) => {
    setCreateNewFile(false);
    setTabName(
      e.target.childNodes[e.target.childNodes.length - 1]?.textContent
    );
    setSelectedFile(file.filename);
    setNewFileName("");

    if (totalFiles.length > 0) {
      totalFiles.map(async (file) => {
        if (
          file.originalname ===
          e.target.childNodes[e.target.childNodes.length - 1]?.textContent
        ) {
          if (
            file.type === "png" ||
            file.type === "jpg" ||
            file.type === "mp4"
          ) {
            setOpenImg(true);
            setImgFile(file);
            setVidFile(file);
          } else {
            setOpenImg(false);

            const res = await axios.get(
              `${STATIC_HOST}project/${projectID}/${file.filename}` //get content file
            );

            setFileURL(file.url);
            setOriginalContent(res.data);
            setCurrentFileID(file.filename);
            setLanguage(
              file.type
                ? file.type
                : file.originalname.substring(
                    file.originalname.lastIndexOf(".") + 1
                  )
            );
            setFileType(
              file.type
                ? file.type
                : file.originalname.substring(
                    file.originalname.lastIndexOf(".") + 1
                  )
            );
            if (file.type === "json") {
              setContent(JSON.stringify(res.data));
            } else {
              setContent(res.data);
            }
          }
        }
      });
    } else {
      fileData?.files.map(async (file) => {
        //get content of file
        if (
          file.includes(
            e.target.childNodes[e.target.childNodes.length - 1].textContent
          )
        ) {
          const response = await axios.get(
            `${STATIC_HOST}project/${fileData.name}/${file}`
          );
          const url = (await axios.get(`${STATIC_HOST}file/${file}`)).data.url;
          setFileURL(url);
          setOriginalContent(response.data);
          setContent(response.data);
          setCurrentFileID(file);
          setProjectID(fileData.name);

          const res = await axios.get(`${STATIC_HOST}file/${file}`);
          setFileType(res.data.type);
          setLanguage(res.data.type);
        }
      });
    }
  };

  if (!isSaving || tabName !== "" || compareContent !== 0) {
    document.onkeydown = (e) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        handleFileEdit();
      }
    };
  }

  const handleFileName = (newFileName) => {
    switch (language) {
      case "js":
        return newFileName + ".js";
      case "javascript":
        return newFileName + ".js";
      case "css":
        return newFileName + ".css";
      case "html":
        return newFileName + ".html";
      case "python":
        return newFileName + ".py";
      case "csv":
        return newFileName + ".csv";
      case "json":
        return newFileName + ".json";
      default:
        return newFileName;
    }
  };

  const convertFileName = (x) => {
    if (x === "javascript") {
      return "js";
    } else if (x === "python") {
      return "py";
    } else {
      return x;
    }
  };

  const useAutoFocus = () => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    return inputRef;
  };

  //reset input when clicked close modal
  document
    .getElementById("closeicon-rename-modal")
    ?.addEventListener("click", function () {
      setNewFileName("");
      setDuplicateFile(false);
    });

  document
    .getElementById("closebtn-rename-modal")
    ?.addEventListener("click", function () {
      setNewFileName("");
      setDuplicateFile(false);
    });

  const handleCopy = (x) => {
    if (!navigator || !navigator.clipboard) return;
    navigator.clipboard.writeText(
      `${STATIC_HOST}project/${projectID}/${x.originalname}`
    );
    toast.success("URL Copied!");
  };

  const [oldFilename, setOldFilename] = useState("");
  const [rename, setRename] = useState(false);

  const handleRenameFile = (x) => {
    setRename(true);
    document.getElementById("open-modal-rename")?.click();
    setOldFilename(x.originalname);
    setFileID(x.filename);
  };

  useEffect(() => {
    if (rename) {
      document.getElementById("input-filename").value = oldFilename;
    }
  }, [oldFilename, rename]);

  useEffect(() => {
    if (rename) {
      let listFiles = [];
      totalFiles.map((x) => listFiles.push(x.originalname));

      if (newFileName.includes(".")) {
        if (listFiles.indexOf(newFileName) > -1) {
          setDuplicateFile(true);
        } else {
          setDuplicateFile(false);
          setNewFileName(newFileName);
        }
      } else {
        if (
          listFiles.indexOf(newFileName + "." + convertFileName(fileType)) > -1
        ) {
          setDuplicateFile(true);
        } else {
          setDuplicateFile(false);
          setNewFileName(newFileName);
        }
      }
    } else {
      let listFiles = [];
      totalFiles.map((x) => listFiles.push(x.originalname));
      if (newFileName.includes(".")) {
        if (listFiles.indexOf(newFileName) > -1) {
          setDuplicateFile(true);
        } else {
          setDuplicateFile(false);
          setNewFileName(newFileName);
        }
      } else {
        if (
          listFiles.indexOf(newFileName + "." + convertFileName(fileType)) > -1
        ) {
          setDuplicateFile(true);
        } else {
          setDuplicateFile(false);
          setNewFileName(newFileName);
        }
      }
    }
  }, [fileType, newFileName]);

  const handleInputFile = (e) => {
    setNewFileName(e.target.value);
  };

  const handleEditFilename = async () => {
    if (rename) {
      if (totalFiles.length > 0) {
        for (const file of totalFiles) {
          if (file.originalname === newFileName) {
            setDuplicateFile(true);
          } else {
            try {
              const res = await axios.post(`${STATIC_HOST}file/${fileID}`, {
                filename: newFileName,
              });
              setTotalFiles((prevTotalFiles) => {
                const fileToReplaceIndex = prevTotalFiles.findIndex(
                  (file) => file.originalname === oldFilename
                );

                if (fileToReplaceIndex === -1) {
                  return [...prevTotalFiles, res.data];
                } else {
                  const updatedFiles = [...prevTotalFiles];
                  updatedFiles[fileToReplaceIndex] = res.data;
                  return updatedFiles;
                }
              });
            } catch (error) {
              console.error("Error occurred while updating the file:", error);
            }
          }
        }
      }
    } else {
      //create new file

      setNewFileName(
        newFileName.includes(".") ? newFileName : handleFileName(newFileName)
      );
      setTabName(
        newFileName.includes(".") ? newFileName : handleFileName(newFileName)
      );
      setCurrentFileID("");
    }
  };

  useEffect(() => {
    if (newFileName.includes(".")) {
      if (newFileName.substring(newFileName.lastIndexOf(".") + 1) == "js") {
        setFileType("js");
        setLanguage("js");
      } else {
        setFileType(newFileName.substring(newFileName.lastIndexOf(".") + 1));
        setLanguage(newFileName.substring(newFileName.lastIndexOf(".") + 1));
      }
    }
  }, [newFileName]);

  function formatJSON(val = {}) {
    try {
      const res = JSON.parse(val);
      return JSON.stringify(res, null, 2);
    } catch {
      const errorJson = {
        error: `error${val}`,
      };
      return JSON.stringify(errorJson, null, 2);
    }
  }

  const handleDeleteFile = async (x) => {
    try {
      const res = await axios.delete(`${STATIC_HOST}file/${x.filename}`, {
        data: {
          uid: uid,
          project: projectID,
        },
      });
      if (res.status === 200) {
        setTotalFiles((prevTotalFiles) => {
          const updatedFiles = prevTotalFiles.filter(
            (filename) => filename.filename !== x.filename
          );
          return [...updatedFiles];
        });
        toast.success(`File deleted successfully`);
        window.location.reload(false);
        const fileExtension = x.originalname.split(".").pop().toLowerCase();

        if (fileExtension === "html") {
          setTempHTML("");
        } else if (fileExtension === "css") {
          setTempCSS("");
        } else if (fileExtension === "js" || fileExtension === "javascript") {
          setTempJS("");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [srcDoc, setSrcDoc] = useState("");

  useEffect(() => {
    setSrcDoc(`${tempHTML}`);
  }, [tempHTML]);

  const getLogo = (file) => {
    let logo = null;
    if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "js" ||
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "javascript"
    ) {
      logo = <img src={JS_Logo} alt="JS Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "css"
    ) {
      logo = <img src={Css_Logo} alt="Css Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "html"
    ) {
      logo = <img src={HTML_Logo} alt="Html Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "json"
    ) {
      logo = <img src={Json_Logo} alt="Json Logo" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "png" ||
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "jpg"
    ) {
      logo = <img src={file.url} alt="Upload Image" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
      "py"
    ) {
      logo = <img src={Python} alt="Python file" className="w-[17px]" />;
    } else if (
      file.originalname?.substring(file.originalname.lastIndexOf(".") + 1) ===
        "txt" ||
      file.originalname?.substring(
        file.originalname.lastIndexOf(".") + 1 === "md"
      )
    ) {
      logo = <img src={Info} alt="text file" className="w-[17px]" />;
    }
    return logo;
  };

  return (
    <div className="h-full pb-1">
      {loading && (
        <Dialog>
          <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="w-10 h-10 flex items-center justify-center">
              <div
                style={{ width: `35px`, height: `35px` }}
                className="animate-spin"
              >
                <div className="h-full w-full border-4 border-t-blue-500 border-b-blue-700 rounded-[50%]"></div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {/* rename modal */}
      <div>
        <button
          type="button"
          className="hidden"
          data-hs-overlay="#hs-focus-management-modal-rename"
          id="open-modal-rename"
        >
          Open modal
        </button>
        <div
          id="hs-focus-management-modal-rename"
          className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
        >
          <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
              <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                <h3 className="font-bold text-gray-800 dark:text-white">
                  {rename ? "Change file name" : "Add new file"}
                </h3>
                <button
                  id="closeicon-rename-modal"
                  type="button"
                  className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                  data-hs-overlay="#hs-focus-management-modal-rename"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3.5 h-3.5"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-4 p-4 overflow-y-auto flex justify-center items-center gap-x-2">
                <div className="w-[350px]">
                  <input
                    type="text"
                    required
                    id="input-filename"
                    className="border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    placeholder="Enter your file name"
                    autoFocus
                    onChange={handleInputFile}
                  />
                </div>
                <div className="">
                  <select
                    className="border rounded-sm placeholder:italic placeholder:text-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 py-2 px-2 h-[46px]"
                    onChange={(e) => {
                      setLanguage(e.target.value || "text");
                      setFileType(e.target.value || fileType);
                    }}
                    value={
                      fileType === "md" || fileType === "txt"
                        ? "text"
                        : fileType
                    }
                  >
                    <option value="js">Javascript</option>
                    <option value="html">HTML</option>
                    <option value="css">CSS</option>
                    <option value="python">Python</option>
                    <option value="text">Plain Text</option>
                    <option value="json">JSON</option>
                    <option value="xml">XML</option>
                    <option value="text">CSV</option>
                  </select>
                </div>
              </div>
              {duplicateFile && (
                <span className="px-9 pb-3 overflow-y-auto text-red-500 text-sm">
                  File is already existed
                </span>
              )}

              <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                <button
                  type="button"
                  id="closebtn-rename-modal"
                  className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                  data-hs-overlay="#hs-focus-management-modal-rename"
                >
                  Close
                </button>
                <button
                  data-hs-overlay="#hs-focus-management-modal-rename"
                  className={`py-3 px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white ${
                    duplicateFile
                      ? "bg-blue-300"
                      : "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
                  }`}
                  onClick={handleEditFilename}
                  disabled={duplicateFile}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* confirm modal */}
      <div>
        <button
          type="button"
          className="hidden"
          data-hs-overlay="#hs-focus-management-modal-confirm"
          id="open-modal-confirm"
        >
          Open modal
        </button>
        <div
          id="hs-focus-management-modal-confirm"
          className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
        >
          <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
              <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                <h3 className="font-bold text-gray-800 dark:text-white">
                  Overwrite existing file?
                </h3>
                <button
                  id="closeicon-confirm-modal"
                  type="button"
                  className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                  data-hs-overlay="#hs-focus-management-modal-confirm"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3.5 h-3.5"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-4 p-4 overflow-y-auto">
                <p>
                  A file with this name already exists. Do you want to overwrite
                  this file?
                </p>
              </div>

              <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                <button
                  type="button"
                  id="nobtn-confirm-modal"
                  className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                  data-hs-overlay="#hs-focus-management-modal-confirm"
                >
                  No
                </button>
                <button
                  data-hs-overlay="#hs-focus-management-modal-confirm"
                  id="yesbtn-confirm-modal"
                  className={`py-3 px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white ${"bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"}`}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-full relative">
        <ToastContainer
          autoClose={2500}
          draggablePercent={60}
          style={{ width: "250px", height: "100px", fontSize: "13px" }}
        />

        {/* sidebar part */}
        <div
          id="docs-sidebar"
          className="z-30 h-full flex flex-col w-64 bg-slate-50 border-r border-gray-200"
        >
          {projectID && (
            <div className="px-2 py-1 flex w-full border-b border-slate-300 py-2 bg-slate-200">
              {/* <div className="font-mono">Project Name </div> */}
              <div className="font-mono flex w-full font-bold leading-none">
                <div className="grow max-h-[34px] overflow-hidden">
                  {" "}
                  {fileData ? fileData?.originalname : newProjectName}
                </div>

                {/* projectDropdown */}
                <div className="hs-dropdown relative inline-flex">
                  <button
                    id="hs-dropdown-default"
                    type="button"
                    className="hs-dropdown-toggle grid place-items-center w-8 px-1 py-1 hover:bg-gray-100 rounded-md"
                  >
                    <svg
                      className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>

                  <div
                    className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 opacity-0 w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                    aria-labelledby="hs-dropdown-default"
                  >
                    <a
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                      href="#"
                    >
                      Rename
                    </a>
                    <a
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                      href="#"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="w-full grow overflow-y">
            {totalFiles.map((file, index) => (
              <div key={index}>
                <ul
                  className={`flex flex-col font-medium px-2 hover:bg-slate-200 cursor-pointer
                        ${selectedFile === file.filename && "bg-slate-200"}
                        `}
                >
                  <div className="w-full flex justify-between">
                    <li
                      className={`relative inline-flex items-center gap-x-2 py-2 px-2 text-sm 
                        text-gray-800 dark:text-white  flex`}
                      onClick={(e) => handleClickFile(e, file)}
                      id="file"
                    >
                      {getLogo(file)}

                      {file?.originalname}
                    </li>
                    <div className="hs-dropdown relative inline-flex">
                      <button
                        id="hs-dropdown-with-dividers"
                        type="button"
                        className="hs-dropdown-toggle"
                      >
                        <FiMoreHorizontal />
                      </button>
                      <div
                        className="absolute z-30 hs-dropdown-menu hidden transition-[opacity,margin] duration hs-dropdown-open:opacity-100
                              opacity-0 min-w-[7rem] bg-white shadow-md rounded-lg p-2 mt-2 divide-y divide-gray-200 dark:bg-gray-800 
                              dark:border dark:border-gray-700 dark:divide-gray-700"
                        aria-labelledby="hs-dropdown-with-dividers"
                      >
                        <div className="first:pt-0 last:pb-0 hover:bg-gray-100">
                          <button
                            className="flex items-center gap-x-3.5 py-1 px-1 rounded-md text-sm text-gray-800 focus:ring-2 
                                    focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            onClick={() => {
                              handleCopy(file);
                            }}
                          >
                            Copy URL
                          </button>
                        </div>
                        <div className="first:pt-0 last:pb-0 hover:bg-gray-100">
                          <button
                            className="flex items-center gap-x-3.5 py-1 px-1 rounded-md text-sm text-gray-800 focus:ring-2 
                                    focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            onClick={() => {
                              window.open(
                                `${STATIC_HOST}project/${projectID}/${file.originalname}`,
                                "_blank"
                              );
                            }}
                          >
                            Open Preview In New Tab
                          </button>
                        </div>
                        <div className="first:pt-0 last:pb-0 hover:bg-gray-100">
                          <button
                            className="flex items-center gap-x-3.5 py-1 px-1 rounded-md text-sm text-gray-800 focus:ring-2 
                                focus:ring-blue-500  dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            onClick={() => handleRenameFile(file)}
                          >
                            Rename
                          </button>
                        </div>
                        <div className="first:pt-0 last:pb-0 hover:bg-gray-100">
                          <button
                            className="flex items-center gap-x-3.5 py-1 px-1 rounded-md text-sm text-gray-800 focus:ring-2 
                                focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            onClick={() => {
                              handleDeleteFile(file);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            ))}
          </div>

          <div className="px-4 w-full border-t border-slate-300 pt-2 border-gray-200">
            <div className="w-full">
              <FileDropzone
                treeData={treeData}
                setTreeData={setTreeData}
                projectID={projectID}
                setTotalFiles={setTotalFiles}
                totalFiles={setTotalFiles}
                setLoading={setLoading}
              />
            </div>
            <button
              type="button"
              onClick={handleAddNewFile}
              disabled={newFileName != ""}
              className={`py-3 w-full text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white ${
                newFileName != ""
                  ? "bg-blue-300"
                  : "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
              }`}
            >
              Add new file
            </button>
          </div>
        </div>

        {/* Editor part */}
        {(tabName != "" || createNewFile) && (
          <div className="grow pr-[25px] h-full">
            <div className="flex justify-between">
              <div className="border-b border-gray-200 dark:border-gray-700">
                <nav
                  className={`flex space-x-2 `}
                  aria-label="Tabs"
                  role="tablist"
                >
                  {/* {(projectFiles.length > 0 || fileData || id !== "new-file") && */}
                  {(fileData || id !== "new-file") && (
                    <button
                      className="hs-tab-active:bg-whFconite hs-tab-active:border-b-transparent hs-tab-active:text-blue-600 dark:hs-tab-active:bg-gray-800 dark:hs-tab-active:border-b-gray-800 dark:hs-tab-active:text-white -mb-px py-3 px-4 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-gray-500 rounded-t-lg hover:text-gray-700 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 active"
                      id="card-type-tab-item-1"
                      data-hs-tab="#card-type-tab-1"
                      aria-controls="card-type-tab-1"
                      role="tab"
                      disabled={tabName !== ""}
                    >
                      {/* {tabName ? tabName : "Choose file to open..."} */}
                      {newFileName
                        ? newFileName
                        : tabName
                        ? tabName
                        : "Choose file to open..."}
                    </button>
                  )}
                </nav>
              </div>
              <div className="flex gap-5">
                <div className="border-b-2 border-blue-600 flex items-center px-3 font-medium text-blue-600 text-lg rounded">
                  {language === "js" || language === "javascript"
                    ? "JavaScript"
                    : language.toUpperCase()}
                </div>
                <button
                  className={`py-1 px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white ${
                    tabName === "" ||
                    compareContent === 0 ||
                    duplicateFile === true
                      ? "bg-blue-300"
                      : "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
                  }`}
                  onClick={handleFileEdit}
                  disabled={
                    isSaving ||
                    tabName === "" ||
                    compareContent === 0 ||
                    duplicateFile === true
                  }
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
            {openImg ? (
              <div className="m-auto max-h-[600px] pt-5">
                {imgFile.type === "png" || imgFile.type === "jpg" ? (
                  <img src={imgFile.url} />
                ) : (
                  <video src={vidFile.url} />
                )}
              </div>
            ) : (
              <div className="mt-3">
                <div className="relative mt-2 border rounded-md bg-white h-full">
                  <Editor
                    className=""
                    height="calc(100vh - 173px)"
                    defaultLanguage={
                      language === "js"
                        ? "javascript"
                        : language === "md" || language === "txt"
                        ? "text"
                        : language
                    }
                    defaultValue="// Write your code here..."
                    onChange={handleContentChange}
                    language={
                      language === "js"
                        ? "javascript"
                        : language === "md" || language === "txt"
                        ? "text"
                        : language
                    }
                    value={
                      createNewFile
                        ? "// Write your code here..."
                        : language === "json"
                        ? formatJSON(content)
                        : content
                    }
                    options={{ minimap: { enabled: false } }}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {tabName == "" && (
          <div className="grow flex items-center justify-center text-blue-600 text-lg">
            Choose file to open
          </div>
        )}

        {tabName != "" && (
          <div className="">
            {/* <ResizeIframe
              srcDoc={srcDoc}
              initialWidth={500}
              initialHeight={900}
            /> */}
            <iframe src={`${tempHTML}`} width="500px" height="900px"></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default Project;
