export const convertLang = (lang) => {
  if (!lang) return "";
  switch (lang) {
    case "js":
      return "javascript";
      break;
    case "md":
      return "plaintext";
      break;
    case "txt":
      return "plaintext";
      break;
    case "text":
      return "plaintext";
      break;
    case "py":
      return "python";
      break;

    default:
      return lang;
  }
};
