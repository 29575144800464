import React, { useEffect, useState, useRef, useCallback } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { STATIC_HOST } from "../constant/config";
import ProjectSideBar from "./ProjectSideBar";
import FileEditor from "./FileEditor";
import LoadingDialog from "../components/LoadingDialog";
import { toast, ToastContainer } from "react-toastify";
import { RiContractLeftLine } from "react-icons/ri";
import { RiContractRightLine } from "react-icons/ri";
import { VscFiles } from "react-icons/vsc";
import { VscBroadcast } from "react-icons/vsc";
import Tooltip from "@mui/material/Tooltip";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const Project = () => {
  let { id } = useParams();
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [loading, setLoading] = useState(false);
  const [totalFiles, setTotalFiles] = useState([]);
  const [projectName, setProjectName] = useState("project");
  const [projectCode, setProjectCode] = useState("code");
  const [previewWidth, setPreviewWidth] = useState("640px");
  const [previewUrl, setPreviewUrl] = useState("");
  const [activeFile, setActiveFile] = useState(null);
  const [contract, setContract] = useState(false);

  const [previewMarkdown, setPreviewMarkdown] = useState("");
  const [previewHTML, setPreviewHTML] = useState("");

  const [hideSidebar, setHideSidebar] = useState(false);
  const [hidePreview, setHidePreview] = useState(true);
  const [contentChanged, setContentChanged] = useState(false);

  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
  }, []);

  useEffect(() => {
    fetchFileData();
  }, [id]);

  const genUrlForAFile = (filename) =>
    `${STATIC_HOST}project/${id}/${filename}`;

  const getFileTypeFromName = (name) => {
    if (!name) return "";
    let splits = name.split(".");
    if (splits.length > 1) return splits[splits.length - 1];
    return "";
  };

  const getOriginalName = async (filename) => {
    const originalname = (await axios.get(`${STATIC_HOST}file/${filename}`))
      .data.originalname;
    return originalname;
  };

  const fetchFileData = async () => {
    if (!id || id.length <= 8) return;
    setLoading(true);
    try {
      const res = await axios.get(`${STATIC_HOST}project/${id}`);
      setProjectCode(res.data.name);
      setProjectName(res.data.originalname);
      let allFiles = [];
      if (res.data.files && res.data.files.length) {
        allFiles = await Promise.all(
          res.data.files.map(async (fileName) => {
            return {
              // originalname: await getOriginalName(fileName),
              originalname: fileName,
              url: genUrlForAFile(fileName),
              filename: fileName,
              type: getFileTypeFromName(fileName),
            };
          })
        );
      }

      allFiles.sort((a, b) => {
        return a.originalname.localeCompare(b.originalname, undefined, {
          sensitivity: "base",
        });
      });

      setTotalFiles(allFiles);
    } catch (err) {
      console.log("error fetching file data", err);
    }
    setLoading(false);
  };

  const onExtendPreview = () => {
    if (contract) {
      setPreviewWidth("640px");
      setContract(false);
    } else {
      setPreviewWidth("100%");
      setContract(true);
    }
  };

  const onHideSideBar = () => {
    setHideSidebar(!hideSidebar);
  };

  const onHidePreview = () => {
    setHidePreview(!hidePreview);
    setContract(false);
  };

  // const getContentPreviewFile = async (x) => {
  //   const res = await axios.get(
  //     `${STATIC_HOST}project/${projectCode}/${x.filename}` //get content file
  //   );
  //   setPreviewMarkdown(res.data);
  // };

  // useEffect(() => {
  //   if (activeFile) {
  //     getContentPreviewFile(activeFile);
  //   }
  // }, [previewUrl]);

  // const getPreviewURLType = (x) => {
  //   return x.toString().substring(x.toString().lastIndexOf(".") + 1);
  // };

  // useEffect(() => {
  //   if (previewUrl) {
  //     if (getPreviewURLType(previewUrl) === "html") {
  //       setPreviewHTML(true);
  //     } else if (getPreviewURLType(previewUrl) === "md") {
  //       setPreviewHTML(false);
  //     }
  //   }
  // }, []);

  return (
    <div className="h-full w-full flex">
      <ToastContainer
        autoClose={2500}
        draggablePercent={60}
        style={{ width: "250px", height: "100px", fontSize: "13px" }}
      />

      {/* Side bar */}
      <div className="bg-slate-300 h-full min-w-[3rem] w-[3rem] box-border">
        <Tooltip title="Explorer" arrow placement="right">
          <div
            className={`flex box-border ${
              hideSidebar
                ? "px-[11px] py-[11px]"
                : "bg-gray-100 border-l-2 border-green-800 px-[11px] py-[10px]"
            }`}
          >
            <VscFiles
              className="cursor-pointer rounded-md"
              onClick={onHideSideBar}
              size="1.6rem"
            />
          </div>{" "}
        </Tooltip>
        <Tooltip title="Live Preview" arrow placement="right">
          <div
            className={`flex box-border ${
              hidePreview
                ? "px-[11px] py-[11px]"
                : "bg-gray-100 border-l-2 border-green-800 px-[11px] py-[10px]"
            }`}
          >
            <VscBroadcast
              size="1.6rem"
              className="cursor-pointer rounded-md"
              onClick={onHidePreview}
            />
          </div>
        </Tooltip>
      </div>

      <div
        className={`bg-slate-300 h-full ${
          hideSidebar ? "hidden" : "min-w-[250px]"
        }`}
      >
        <ProjectSideBar
          projectName={projectName}
          projectCode={projectCode}
          setTotalFiles={setTotalFiles}
          files={totalFiles || []}
          contentChanged={contentChanged}
          setPreviewFile={(file) => {
            setPreviewUrl(file.url);
            setPreviewWidth("640px");
          }}
          setEditFile={(file) => {
            setActiveFile(file);
          }}
          getFileTypeFromName={getFileTypeFromName}
          uid={uid}
          setLoading={setLoading}
          setActiveFile={setActiveFile}
          activeFile={activeFile}
          setHideSidebar={setHideSidebar}
        />
      </div>
      {/* Editor */}
      <div
        className={`grow h-full overflow-hidden ${contract ? "hidden" : ""}`}
      >
        {activeFile && (
          <FileEditor
            file={activeFile}
            projectId={id}
            setContentChanged={setContentChanged}
            contentChanged={contentChanged}
            onContentSaved={() => {
              if (!previewUrl) return;
              let previewIframe = document.getElementById("previewIframe");
              if (previewIframe) {
                previewIframe.src = "";
                setTimeout(() => (previewIframe.src = previewUrl), 500);
              }
            }}
          />
        )}
        {!activeFile && (
          <div className="w-full h-full grid place-items-center text-slate-700 text-2xl opacity-70">
            Nothing to edit
          </div>
        )}
      </div>
      {/* Preview */}
      {previewUrl && (
        <div>
          <div
            className={`bg-white h-full ml-[3%] ${
              hidePreview ? "hidden" : ""
            } overflow-auto resize-x`}
            style={{ width: previewWidth }}
          >
            {!contract && (
              <button
                className="w-10 h-10 hover:bg-gray-100 rounded-md flex justify-center items-center"
                onClick={onExtendPreview}
              >
                <RiContractLeftLine size="1.5rem" />
              </button>
            )}
            {contract && (
              <button
                className="w-10 h-10 hover:bg-gray-100 rounded-md flex justify-center items-center"
                onClick={onExtendPreview}
              >
                <RiContractRightLine size="1.5rem" />
              </button>
            )}

            
          <iframe
            id="previewIframe"
            src={previewUrl}
            width="100%"
            height="100%"
          ></iframe>
            {/* {setPreviewMarkdown && (
              <ReactMarkdown
                className="prose lg:prose-xl"
                children={previewMarkdown}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            )} */}
          </div>
        </div>
      )}
      {/* Loading dialog */}

      {loading && <LoadingDialog />}
    </div>
  );
};

export default Project;
