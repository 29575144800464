import React, { useEffect, useState, useRef, useCallback } from "react";

import axios from "axios";
import { STATIC_HOST } from "../constant/config";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { toast, ToastContainer } from "react-toastify";
import { convertLang } from "../utils/convertLang";
import { createBrowserHistory } from "history";
import { usePrompt } from "../components/usePrompt";

const FileEditor = ({
  file,
  projectId,
  onContentSaved,
  contentChanged,
  setContentChanged,
}) => {
  const [language, setLanguage] = useState("javascript");
  const [fileType, setFileType] = useState("javascript");
  const [content, setContent] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [createNewFile, setCreateNewFile] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [originalContent, setOriginalContent] = useState("");

  useEffect(() => {
    if (!file) {
      setShowEditor(false);
      return;
    }
    loadFileContent(file);
    setShowEditor(true);
  }, [file]);

  useEffect(() => {
    setContentChanged(content != originalContent);
  }, [content, originalContent]);

  const loadFileContent = async (file) => {
    if (!file || !projectId) {
      setOriginalContent("");
      setLanguage("");
      setFileType("");
      setContent("");
      return;
    }

    try {
      const res = await axios.get(
        `${STATIC_HOST}project/${projectId}/${file.filename}` //get content file
      );

      setLanguage(
        file.type
          ? file.type
          : file.originalname.substring(file.originalname.lastIndexOf(".") + 1)
      );
      setFileType(
        file.type
          ? file.type
          : file.originalname.substring(file.originalname.lastIndexOf(".") + 1)
      );
      if (file.type === "json") {
        setContent(JSON.stringify(res.data || {}));
        setOriginalContent(JSON.stringify(res.data || {}));
      } else {
        setContent(res.data || "");
        setOriginalContent(res.data || "");
      }
    } catch (e) {
      console.log("Error on loading file: ", e);
    }
  };

  const handleFileEdit = async (e) => {
    if (!file || !file.filename) setIsSaving(true);
    try {
      await axios.put(`${STATIC_HOST}project/${projectId}/${file.filename}`, {
        content: content || "",
      });
      toast.success("Edit successful");
      setOriginalContent(content);
      if (onContentSaved) onContentSaved(content);
    } catch (error) {
      console.error("Error editing file:", error);
      toast.error("Failed to edit file");
    } finally {
      setIsSaving(false);
    }
  };

  const handleContentChange = (e) => {
    setContent(e);
  };

  function formatJSON(val = {}) {
    try {
      const res = JSON.parse(val);
      return JSON.stringify(res, null, 2);
    } catch {
      const errorJson = {
        error: `error${val}`,
      };
      return JSON.stringify(errorJson, null, 2);
    }
  }

  if (!isSaving || !contentChanged) {
    document.onkeydown = (e) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        handleFileEdit();
      }
    };
  }

  usePrompt("Changes you made may not be saved.", contentChanged === true);

  return (
    <div className="w-full h-full bg-slate-400 flex flex-col">
      {/* Head line */}
      <div className="flex bg-slate-200 px-4 py-1 items-center border-l-[1px] border-gray-300">
        <div className="grow border-b border-gray-200 dark:border-gray-700 font-mono font-semibold text-base truncate">
          {file?.originalname || "no name"}
        </div>
        <div className="mr-3 items-center px-3 text-gray-800 text-base">
          <b>{convertLang(language).toUpperCase()}</b>
        </div>

        <button
          className={`${
            isSaving || !contentChanged
              ? "bg-slate-400"
              : "bg-blue-500 hover:opacity-80"
          } py-[0.34rem] px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md 
                border border-transparent font-semibold text-white  focus:outline-none 
                focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800`}
          onClick={handleFileEdit}
          disabled={isSaving || !contentChanged}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>

      {/* Editor */}
      <div className="grow">
        {!showEditor && (
          <div className="w-full h-full grid place-items-center text-white text-2xl opacity-70">
            No file selected
          </div>
        )}

        {showEditor && (
          <Editor
            className="w-full"
            height="100%"
            defaultLanguage={convertLang(language)}
            defaultValue=""
            onChange={handleContentChange}
            language={
              language === "js"
                ? "javascript"
                : language === "md" || language === "txt"
                ? "text"
                : language
            }
            value={
              createNewFile
                ? ""
                : language === "json"
                ? formatJSON(content)
                : content
            }
            options={{ minimap: { enabled: false } }}
          />
        )}
      </div>
    </div>
  );
};

export default FileEditor;
