import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { STATIC_HOST } from "../constant/config";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Dropzone from "../components/Dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import Non_EmptyFolder from "../utils/images/files-and-folders.png";
import EmptyFolder from "../utils/images/empty-folder.png";
import { BsFillGridFill } from "react-icons/bs";
import { GoRows } from "react-icons/go";
import PaginationButtons from "../components/Paginate";
import LoadingDialog from "../components/LoadingDialog";

import { toast, ToastContainer } from "react-toastify";
MyProjects.propTypes = {};
function MyProjects(props) {
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsName, setAllProjectsName] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [newProjectName, setNewProjectName] = useState("");
  const [duplicateProject, setDuplicateProject] = useState("");
  const [droppedProjectFiles, setDroppedProjectFiles] = useState([]);
  const [drop, setDrop] = useState(0);
  const [totalFiles, setTotalFiles] = useState([]);
  const [project, setProject] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [option, setOption] = useState("grid");
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const userId = useParams();
  const [loading, setLoading] = useState(false);

  const [uid, setUid] = useState(
    Object.keys(userId).length > 0 ? userId.uid : localStorage.getItem("uid")
  );


  let navigate = useNavigate();
  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
    ListAllProjects();
  }, []);
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return dateObj.toLocaleString("en-US", options);
  }
  const useAutoFocus = () => {
    const inputRef = useRef(null);
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
    return inputRef;
  };
  const ListAllProjects = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${STATIC_HOST}project/all/${uid}`);
      setAllProjects(response.data);
      const allProjectsName = response.data.map(
        (project) => project.originalname
      );
      setAllProjectsName(allProjectsName);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleOpenProject = () => {
    document.getElementById("open-modal-button").click();
  };
  const modalRef = useRef(null);
  const nameInput = useAutoFocus();

  const checkProject = (file) => {
    if (file.path.includes("/")) {
      return file.path.split("/")[1];
    } else return "";
  };

  const files = droppedProjectFiles[0]?.map((file) => (
    <li key={file.path}>
      {file.path.split("/")[2]} - {file.size} bytes
    </li>
  ));

  //create project from dropzone
  useEffect(() => {
    const handleDrop = async () => {
      var projectName;
      droppedProjectFiles[0]?.map((x) => {
        projectName = checkProject(x);
      });

      if (projectName === "") {
        toast.error("Please select a project");
      }
      setNewProjectName(projectName);
      if (projectName) {
        document.getElementById("input-label").value = projectName;
      } else {
        setDroppedProjectFiles([]);
      }
      if (allProjectsName?.includes(projectName)) {
        setDuplicateProject(projectName);
      }
    };
    handleDrop();
  }, [droppedProjectFiles[0]]);

  //create project from input
  const handleNewProjectName = (e) => {
    let listFiles = [];
    allProjects.map((x) => listFiles.push(x.originalname));
    if (listFiles.indexOf(e.target.value) > -1) {
      setDuplicateProject(e.target.value);
    } else {
      setNewProjectName(e.target.value);
      setDuplicateProject("");
    }
  };

  const handlePageChange = useCallback((newCurrentItems) => {
    setCurrentItems(newCurrentItems);
  }, []);

  const handleCreateNewProject = async () => {
    try {
      setLoading(true);
      if (droppedProjectFiles.length > 0) {
        const create_project_response = await axios.post(
          `${STATIC_HOST}project`,
          {
            name: newProjectName,
            uid: uid,
          }
        );
        const projectID = create_project_response.data.name;
        setProject(create_project_response.data.originalname);

        await Promise.all(
          droppedProjectFiles[0]?.map(async (file) => {
            const formData = new FormData();
            formData.append(
              "type",
              file.name.substr(file.name.lastIndexOf(".") + 1)
            );
            formData.append("uid", uid);
            formData.append("project", projectID);
            formData.append("uploaded_file", file);
            const add_file_response = await axios.post(
              `${STATIC_HOST}upload/file`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            setTotalFiles((x) => [...x, add_file_response.data]);
          })
        );

        ListAllProjects();
        navigate(`/project/${create_project_response.data.name}`, {
          state: {
            newProjectName: newProjectName,
          },
        });
        toast.success("Created project successfully");
      } else {
        const create_project_response = await axios.post(
          `${STATIC_HOST}project`,
          {
            name: newProjectName,
            uid: uid,
          }
        );
        setNewProjectName(create_project_response.data.originalname);
        setProjectID(create_project_response.data.name);
        navigate(`/project/${create_project_response.data.name}`, {
          state: {
            newProjectName: newProjectName,
          },
        });
        toast.success("Created project successfully");
        ListAllProjects();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Close the project modal
  const handleCloseProject = () => {
    document.getElementById("input-label").value = "";
    setNewProjectName(""); // Reset the input value
    setDuplicateProject("");
  };

  return (
    <div className="w-full select-none z-30">
      <ToastContainer
        autoClose={2500}
        draggablePercent={60}
        style={{ width: "250px", height: "100px", fontSize: "13px" }}
      />
      {loading && <LoadingDialog />}
      {/* create project modal */}
      <div ref={modalRef}>
        <button
          type="button"
          className="hidden"
          data-hs-overlay="#hs-focus-management-modal"
          id="open-modal-button"
        >
          Open modal
        </button>

        <div
          id="hs-focus-management-modal"
          className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
        >
          <div
            className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 
                      opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto"
          >
            <div
              className="flex flex-col bg-white border shadow-sm rounded-xl 
                        dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"
            >
              <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                <h3 className="font-bold text-gray-800 dark:text-white">
                  Create new project
                </h3>
                <button
                  type="button"
                  className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center 
                          h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none 
                          focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all 
                          text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                  data-hs-overlay="#hs-focus-management-modal"
                  onClick={handleCloseProject}
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3.5 h-3.5"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>

              <div className="p-4 overflow-y-auto">
                <label
                  htmlFor="input-label"
                  className="block text-sm font-medium mb-2 dark:text-white"
                >
                  Project Name
                </label>
                <input
                  type="text"
                  required
                  id="input-label"
                  className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                  placeholder="Enter your project name"
                  autoFocus
                  onChange={handleNewProjectName}
                  ref={nameInput}
                />
                {duplicateProject.length > 0 && (
                  <span className="text-rose-500 ml-4 text-sm">
                    {" "}
                    {duplicateProject} is already existed
                  </span>
                )}
              </div>
              <div className={`px-4 pt-2 pb-4 overflow-y-auto`}>
                <p className="text-sm font-medium mb-2">
                  Or drop your new project here
                </p>
                <Dropzone
                  treeData={treeData}
                  setTreeData={setTreeData}
                  setProject={setProject}
                  setDroppedProjectFiles={setDroppedProjectFiles}
                  setDrop={setDrop}
                />
              </div>
              {files && (
                <div className="w-full max-h-[6rem] overflow-y-scroll list-decimal px-6 text-sm">
                  {files}
                </div>
              )}

              <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                <button
                  type="button"
                  className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                  data-hs-overlay="#hs-focus-management-modal"
                  id="close-modal-button"
                  onClick={handleCloseProject}
                >
                  Close
                </button>
                <button
                  data-hs-overlay="#hs-focus-management-modal"
                  className={`py-3 px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white ${
                    duplicateProject.length > 0 ||
                    document.getElementById("input-label")?.value == ""
                      ? "bg-blue-300"
                      : "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
                  }`}
                  onClick={handleCreateNewProject}
                  disabled={
                    duplicateProject.length > 0 ||
                    document.getElementById("input-label")?.value == ""
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center px-2">
        <div className="w-full max-w-[1280px]">
          <div className="flex w-full justify-between items-center pt-3 pb-4">
            <div>
              <button
                className="rounded text-white rounded px-2 py-[5.5px] bg-emerald-500 hover:bg-emerald-400 cursor-pointer"
                onClick={handleOpenProject}
              >
                Create New Project
              </button>
            </div>

            <div className="flex w-[200px] h-[43px] justify-between items-center border-2 rounded-3xl cursor-pointer">
              <div
                className={`w-[100px] h-[43px] flex justify-center items-center   ${
                  option === "grid"
                    ? "border rounded-3xl border-[#005072] text-[#005072] font-bold bg-[#F3F3F3] opacity-100"
                    : "text-slate-500 opacity-30"
                }`}
                onClick={() => {
                  setOption("grid");
                }}
              >
                <BsFillGridFill />
              </div>
              <div
                className={`w-[100px] h-[43px] flex justify-center items-center  ${
                  option === "list"
                    ? " border rounded-3xl border-[#005072] text-[#005072] font-bold bg-[#F3F3F3] opacity-100"
                    : "text-slate-500 opacity-30"
                }`}
                onClick={() => {
                  setOption("list");
                }}
              >
                <GoRows />
              </div>
            </div>
          </div>

          {option === "grid" && (
            <div className="grid lg:grid-cols-6 sm:grid-cols-3 xs:grid-cols-2 gap-4">
              {currentItems.map((file, index) => {
                return (
                  <div className="" key={index}>
                    <div
                      className="inline-flex items-center rounded-md text-base w-full px-5 py-5 
                                bg-slate-100 border-b hover:bg-slate-200 cursor-pointer flex-col justify-center"
                    >
                      {file.files.length === 0 ? (
                        <img
                          src={EmptyFolder}
                          alt=""
                          width="80px"
                          onClick={() =>
                            navigate("/project/" + file.name, {
                              state: {
                                file: file,
                                newProjectName: newProjectName,
                              },
                            })
                          }
                        />
                      ) : (
                        <img
                          src={Non_EmptyFolder}
                          alt=""
                          width="80px"
                          onClick={() =>
                            navigate("/project/" + file.name, {
                              state: {
                                file: file,
                                newProjectName: newProjectName,
                              },
                            })
                          }
                        />
                      )}
                      <Link
                        to={"/project/" + file.name}
                        state={{ file: file, allProjectsName: allProjectsName }}
                        className="grow w-full font-medium truncate text-center"
                      >
                        {file.originalname}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {option === "list" && (
            <div className="w-full">
              {currentItems.map((file, index) => {
                return (
                  <div className="" key={index}>
                    <div className="inline-flex items-center w-full px-5 py-5 bg-slate-100 border-b hover:bg-slate-200 cursor-pointer text-base ">
                      {file.files.length === 0 ? (
                        <img
                          src={EmptyFolder}
                          alt=""
                          width="30px"
                          onClick={() =>
                            navigate("/project/" + file.name, {
                              state: {
                                file: file,
                                allProjectsName: allProjectsName,
                              },
                            })
                          }
                        />
                      ) : (
                        <img
                          src={Non_EmptyFolder}
                          alt=""
                          width="30px"
                          onClick={() =>
                            navigate("/project/" + file.name, {
                              state: {
                                file: file,
                                allProjectsName: allProjectsName,
                              },
                            })
                          }
                        />
                      )}
                      <Link
                        to={"/project/" + file.name}
                        state={{ file: file }}
                        className="ml-6 grow font-medium"
                      >
                        {file.originalname}
                      </Link>
                      {formatDate(file.date)}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="fixed bottom-0 left-0 right-0 flex justify-center mb-4">
            <PaginationButtons
              allProjects={allProjects}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyProjects;
