import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { STATIC_HOST } from "../constant/config";

const FileDropzone = ({
  treeData,
  setTreeData,
  projectCode,
  setTotalFiles,
  setLoading,
  uid,
}) => {
  const [mounted, setMounted] = useState(true);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles[0].path.includes("/")) {
      toast.error("Please select a file");
      setLoading(false);
    } else {
      setLoading(true);

      const formData = new FormData();
      if (projectCode) {
        const res = await axios.get(`${STATIC_HOST}project/${projectCode}`);
        const filePromises = res.data.files?.map(async (x) => {
          const res = await axios.get(`${STATIC_HOST}file/${x}`);
          return {
            originalname: res.data.originalname,
            filename: res.data.filename,
          };
        });
        const resolvedFiles = await Promise.all(filePromises);
        let listFiles = [];
        let listFileNames = [];
        resolvedFiles.map((x) => {
          listFiles.push(x.originalname);
          listFileNames.push(x.filename);
        });
        //Overwrite duplicate file
        if (listFiles.indexOf(acceptedFiles[0].name) > -1) {
          let overWriteFile =
            listFileNames[listFiles.indexOf(acceptedFiles[0].name)];
          document.getElementById("open-modal-confirm").click();
          document
            .getElementById("yesbtn-confirm-modal")
            ?.addEventListener("click", async function () {
              //delete old file
              try {
                const res = await axios.delete(
                  `${STATIC_HOST}file/${overWriteFile}`,
                  {
                    data: {
                      uid: uid,
                      project: projectCode,
                    },
                  }
                );
                if (res.status === 200) {
                  setTotalFiles((prevTotalFiles) => {
                    const updatedFiles = prevTotalFiles.filter(
                      (filename) => filename.filename !== overWriteFile
                    );
                    return [...updatedFiles];
                  });
                }
              } catch (error) {
                console.error(error);
              }
              //add new file
              formData.append("uid", uid);
              formData.append("project", projectCode);
              formData.append("uploaded_file", acceptedFiles[0]);
              formData.append(
                "type",
                acceptedFiles[0].name.substr(
                  acceptedFiles[0].name.lastIndexOf(".") + 1
                )
              );
              axios
                .post(`${STATIC_HOST}upload/file`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((res) => {
                  setTotalFiles((prev) => [...prev, res.data]);
                  window.location.href =
                    window.location.origin +
                    "/project/" +
                    projectCode +
                    "?fileName=" +
                    overWriteFile;
                  toast.success("File overwrited successfully");
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          setLoading(false);
        } else {
          acceptedFiles.forEach(async (file) => {
            const formData = new FormData();
            formData.append(
              "type",
              file.name.substr(file.name.lastIndexOf(".") + 1)
            );
            formData.append("uid", uid);
            formData.append("project", projectCode);
            formData.append("uploaded_file", file);
            axios
              .post(`${STATIC_HOST}upload/file`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                setTotalFiles((prev) =>
                  [...prev, res.data].sort(function (a, b) {
                    return a.originalname.localeCompare(b.originalname);
                  })
                );
                setLoading(false);
                toast.success("File created successfully");
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      } else {
        toast.error("Please create/seclect a project");
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
  });

  const { isDragActive: isDragActiveInside } = useDropzone({
    onDragEnter: () => setHovered(true),
    onDragLeave: () => setHovered(false),
  });

  const isDragActiveOutside = isDragActive || isDragActiveInside;

  return (
    <div
      {...getRootProps()}
      style={{
        height: "5rem",
        width: "100%",
        margin: "auto",
        marginBottom: "10px",
        // position: "fixed",
        // top: "0",
        // left: "0",
        // paddingLeft: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${isDragActiveOutside ? "#888" : "black"}`,
        backgroundColor: isDragActiveOutside || hovered ? "#eee" : "#e5e7eb",
        outline: "none",
        userSelect: "none",
        flexDirection: "column",
        // visibility: `${isDragActiveOutside ? "visible" : "hidden"}`,
        // opacity: `${isDragActiveOutside ? "1" : "0"}`,
        // pointerEvents: `${isDragActiveOutside ? "auto" : "none"}`,
      }}
    >
      <p>Drop new files here</p>
      <input {...getInputProps()} />
      <button
        type="button"
        onClick={open}
        className="text-blue-500 text-sm underline "
      >
        Choose files
      </button>
      <SortableTree
        treeData={treeData}
        onChange={setTreeData}
        className="!w-0 !h-0 !p-0"
      />
    </div>
  );
};

export default FileDropzone;
