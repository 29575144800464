import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useState } from "react";

export default function PaginationButtons({
  allProjects,
  onPageChange,
  currentPage,
  setCurrentPage,
  option,
}) {
  const [currentItems, setCurrentItems] = useState([]);
  let itemsPerPage;
  if (option === "grid") {
    itemsPerPage = 18;
  } else if (option === "list") {
    itemsPerPage = 8;
  }
  const pageCount = Math.ceil(allProjects.length / itemsPerPage);
  const itemOffset = currentPage * itemsPerPage;

  useEffect(() => {
    const newCurrentItems = allProjects.slice(
      itemOffset,
      itemOffset + itemsPerPage
    );
    setCurrentItems(newCurrentItems);
    // Call the callback function to pass the currentItems to the parent
    onPageChange(newCurrentItems);
  }, [currentPage, allProjects, option]);

  const handlePageClick = (value) => {
    setCurrentPage(value - 1);
  };

  return (
    <div>
      {pageCount > 1 && (
        <Stack spacing={2}>
          <Pagination
            count={pageCount}
            shape="rounded"
            // onChange={handlePageClick}
            onChange={(event, value) => {
              handlePageClick(value);
            }}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Stack>
      )}
    </div>
  );
}
